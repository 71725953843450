.skills {
  //min-height: 100vh;
  flex-direction: column;
  background-color: var(--white-color);

  @media (min-width: 320px) and (max-width: 480px) {
    .head-text {
      font-size: 2rem !important;
      margin-top: 3rem;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .head-text {
      font-size: 2.2rem !important;
    }
  }
}

.experience__skills {
  flex-wrap: wrap;
  margin-bottom: 3rem;
  justify-content: center;
  div {
    width: 90px;
    height: 90px;
    background-color: var(--white-color);
    padding: 1rem;
    border-radius: 50%;
    margin: 4rem 1rem 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    p {
      margin-top: 0.9rem;
      text-align: center;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    @media (min-width: 320px) and (max-width: 480px) {
      width: 70px;
      height: 70px;
    }
  }
}

.experience__companies {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 6rem auto 2rem;
  max-width: 60%;

  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 80%;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: 90%;
  }

  .experience__companies-tablist {
    display: flex;
    flex-direction: column;

    flex-wrap: wrap;

    button {
      cursor: pointer;
      border: 0px;
      border-radius: 0px;

      display: flex;
      align-items: center;
      border-left: 2px solid;
      padding: 0px 20px 2px;
      height: 42px;
      background-color: transparent;
      color: var(--secondary-color);
      font-size: 0.8rem;
      font-weight: 600;
      width: 220px;

      @media (min-width: 481px) and (max-width: 767px) {
        width: 220px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      @media (min-width: 320px) and (max-width: 480px) {
        padding: 0px 12px 2px;
        border-left: 0;
      }
    }

    .item-active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .experience__companies-jobs {
    margin-left: 3rem;

    h3 {
      font-size: 1.25rem;
      color: var(--gray-color);
    }
    p:nth-child(3) {
      margin-top: 1.5rem;
      text-align: justify;

      &::before {
        content: "▹";
        color: var(--secondary-color);
        margin-right: 3px;
        font-size: 1rem;
      }
    }

    @media screen and (max-width: 767px) {
      margin-top: 2.5rem;
      margin-left: 0;
    }
  }
}
