.home {
  min-height: 100vh;
  background: url("../../assets/bgIMG.png");
  background-size: cover;
  background-repeat: repeat;
  border-bottom: 1px solid rgba(168, 168, 168, 0.15);

  display: flex;
  justify-content: center;

  font-family: var(--font-manrope);

  @media screen and (max-width: 912px) {
    min-height: 100vh;
  }

  .home__desc {
    margin-top: 6rem;
    width: 70%;

    h1 {
      font-weight: 700;
      font-size: 6rem;
      color: var(--black-color);
    }

    h2 {
      font-weight: 500;
      font-size: 3rem;
      color: var(--gray-color);
    }

    p {
      font-size: 1.3rem !important;
      max-width: 700px;
      line-height: 20px;
      margin-top: 1rem;
      margin-bottom: 4rem;
    }
    span {
      font-size: 1.5rem;
      color: var(--secondary-color);
    }

    a {
      outline: none;
      padding: 1rem 3rem;
      background-color: transparent;
      border: 1px solid var(--secondary-color);
      color: var(--secondary-color);
      border-radius: 5px;
      text-decoration: none;
      &:hover {
        background-color: var(--secondary-color);
        color: #fff;
      }
    }

    @media (min-width: 1025px) and (max-width: 1280px) {
      h1 {
        font-size: 4rem;
      }
      h2 {
        font-size: 2.4rem;
      }
      p {
        font-size: 1.2rem;
      }
      span {
        font-size: 1.3rem;
      }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      h1 {
        font-size: 3.5rem;
      }
      h2 {
        font-size: 1.7rem;
      }
      p {
        font-size: 1rem !important;
      }
      span {
        font-size: 1.1rem;
      }
      a {
        padding: 0.8rem 2.5rem;
      }
    }

    @media screen and (max-width: 767px) {
      width: 90%;

      h1 {
        font-size: 2.7rem;
      }
      h2 {
        font-size: 1.5rem;
      }
      p {
        font-size: 1rem !important;
      }
      span {
        font-size: 1.1rem;
      }
      a {
        padding: 0.8rem 2.5rem;
      }
    }
  }
}
