.footer {
  //min-height: 100vh;
  background-color: var(--white-color);

  flex-direction: column;

  @media (min-width: 320px) and (max-width: 480px) {
    .head-text {
      font-size: 2rem !important;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .head-text {
      font-size: 2.2rem !important;
    }
  }

  p {
    font-size: 1.2rem;
    text-align: center;
  }

  .contact {
    flex-direction: column;
    width: 60%;
    margin: 50px auto 0;

    input,
    textarea {
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: var(--black-color);
      background-color: var(--white-color);
      border: 1px solid #ced4da;
      border-radius: 0.375rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    input {
      margin-bottom: 10px;
    }

    textarea {
      resize: vertical;
    }

    .contact-button {
      padding: 0.7rem 1rem;
      border-radius: 10px;
      margin-top: 2rem;
      background-color: var(--primary-color);
      border-style: none;
      cursor: pointer;
      border-color: var(--primary-color);
    }

    @media screen and (max-width: 767px) {
      width: 90%;
    }
  }

  .contact__thanks {
    margin-top: 6rem;
  }
}
