.work {
  //min-height: 100vh;
  flex-direction: column;

  @media (min-width: 320px) and (max-width: 480px) {
    .head-text {
      font-size: 2rem !important;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .head-text {
      font-size: 2.2rem !important;
    }
  }

  .work__platforms {
    flex-wrap: wrap;
    justify-content: center;
    margin: 3rem 0;

    div {
      margin: 1rem;
      border-radius: 10px;
      background-color: var(--white-color);
      padding: 0.5rem 0.8rem;

      text-decoration: none;
      color: var(--black-color);
      font-weight: 500;

      cursor: pointer;

      &:hover {
        background-color: var(--secondary-color);
        color: var(--white-color);
      }
    }
    .item-active {
      background-color: var(--secondary-color);
      color: var(--white-color);
    }
  }

  .work__projects {
    flex-wrap: wrap;
    justify-content: center;
    .work__projects-project {
      width: 370px;
      background-color: var(--white-color);
      padding: 1rem 0.5rem;
      border-radius: 7px;
      margin: 1rem;

      position: relative;

      .work__projects-img {
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .github,
        .eye {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .img-hover {
          display: none;

          div {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.5);

            margin: 0 0.7rem;

            svg {
              width: 50%;
              height: 50%;
              color: var(--white-color);
            }

            &:hover {
              background-color: rgba(255, 255, 255, 0.7);

              svg {
                color: var(--black-color);
              }
            }
          }
        }

        &:hover {
          .img-hover {
            display: flex;
            justify-content: center;
            align-items: center;

            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);

            transition: all 0.3s ease;
            cursor: pointer;
          }
        }
      }

      p {
        text-align: center;
        margin-top: 0.3rem;
        font-weight: 500;
      }
      a {
        text-decoration: none;

        &:hover {
          color: var(--secondary-color);
        }
      }
      .work__projects-tag {
        position: absolute;
        background-color: var(--white-color);
        padding: 0.5rem 0.8rem;
        top: 0;
        left: 0;
      }
    }
  }
}
