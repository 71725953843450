.header {
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 0 rgba(168, 168, 168, 0.15);
  margin-bottom: 1rem;
}

.navbar {
  display: flex;
}

.navbar__logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    font-size: 25px;
    text-transform: uppercase;
    font-family: var(--font-manrope);
    font-weight: 800;
    color: var(--secondary-color);
  }
}

.navbar__links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;

  li {
    cursor: pointer;
    margin: 0 0.65rem;

    a {
      color: var(--gray-color);
      text-decoration: none;
      font-weight: 500;
      text-transform: uppercase;

      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--secondary-color);
      }
    }

    div {
      width: 5px;
      height: 5px;
      background: transparent;
      border-radius: 50%;
      margin: 5px auto;
    }
    &:hover {
      div {
        background-color: var(--secondary-color);
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.navbar__menu {
  .navbar__menu-open {
    background-color: var(--secondary-color);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;

    display: none;

    svg {
      color: var(--white-color);
      width: 70%;
      height: 70%;
    }
  }

  div {
    width: 80%;
    height: 100vh;

    background: url("../../assets/bgWhite.png");
    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;

    box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;

    svg {
      width: 40px;
      height: 40px;
      color: var(--secondary-color);
    }

    ul {
      list-style: none;
      margin-top: 5rem;

      li {
        margin: 2rem 1rem 0;
      }

      a {
        color: var(--gray-color);
        text-decoration: none;
        font-weight: 500;
        text-transform: uppercase;

        transition: all 0.3s ease-in-out;

        &:hover {
          color: var(--secondary-color);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .navbar__menu-open {
      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      right: 30px;
    }
    .navbar__menu-close {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
}
