.container {
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
}

.padding-main {
  padding: 3rem 5px;
}

.wrapper {
  width: 90%;
}

.col-20 {
  width: 20%;
}

.col-30 {
  width: 30%;
}

.col-50 {
  width: 50%;
}

.col-70 {
  width: 70%;
}

.margin-auto {
  margin: 0 auto;
}

.flex-center {
  display: flex;
  align-items: center;
  //justify-content: center;
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--black-color);
  text-transform: capitalize;
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;
}

.bold-text {
  font-size: 1rem;
  font-weight: 800;
  color: var(--black-color);
  text-align: left;
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 40px;

  .navigation__dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #cbcbcb;

    margin-bottom: 0.5rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--secondary-color);
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
}

.social {
  display: flex;
  flex-direction: column;

  position: fixed;
  left: 20px;

  div {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: var(--white-color);
    border: 1px solid var(--lightGray-color);

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0.25rem 0;

    transition: all 0.3s ease-in-out;

    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      color: var(--gray-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
}
