.about {
  //min-height: 100vh;
  flex-direction: column;
  background-color: var(--white-color);

  @media screen and (max-width: 1280px) {
    h2 {
      margin-top: 2rem;
    }
  }

  .about__skills {
    flex-wrap: wrap;
    margin-top: 2rem;
    justify-content: center;

    .about__skill {
      max-width: 300px;
      height: 300px;
      margin: 2rem 1.56rem 3rem 0;

      @media (min-width: 320px) and (max-width: 480px) {
        margin-right: 0;
      }
    }

    img {
      width: 100%;
      border-radius: 15px;
      object-fit: cover;
    }

    h3 {
      margin-top: 0.5rem;
    }
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .head-text {
      font-size: 2rem !important;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .head-text {
      font-size: 2.2rem !important;
    }
  }
}
